/* global $ */
class App {
    bootstrap;
    menuMoving = false;

    constructor() {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initCarousel();
        this.initMatrixContent();
        this.initFontAwesome();
        this.initFancybox();
        this.initStickyMenu();
        this.initCard();
        this.initRellax();
        this.initSVGInjector();
        $('body').css('opacity', 1);
        console.log('App initialized');
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () { };
        }
    }

    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone, .dropdown-toggle').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initRellax() {
        if ($('.rellax').length) {
            import(/* webpackChunkName: "Rellax" */ './components/rellax').then(Rellax => {
                var rellax = new Rellax.Rellax('.rellax');
                rellax.refresh();
            });
        }
    }

    initSVGInjector() {
        if ($('.inject-me').length) {
            import(/* webpackChunkName: "svgInjector" */ './components/svgInjector').then(SVGInjector => {
                var mySVGsToInject = document.querySelectorAll('img.inject-me');
                SVGInjector.SVGInjector(mySVGsToInject);
            });
        }
    }

    initCard() {
        if ($('.card').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/card');
        }
    }

    initFancybox() {
        if ($('.fancybox').length) {
            import(/* webpackChunkName: "fancybox" */ './components/fancybox');
        }
    }

    initForms() {
        $('#applyJob').click(function () {
            if ($('#formidable_job_application_form-messages').length) {
                let idf = $('#formidable_job_application_form-messages').parent().parent().parent().parent().attr('id');
                $('html, body').animate({
                    scrollTop: $("#" + idf).offset().top
                })
            }

        });

        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"), (i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    initToasts(elems) {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems) {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    initCarousel() {
        import(/* webpackChunkName: "carousel" */ './components/carousel').then(carousel => {
            new carousel.Carousel;
        });
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 100) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });

        var myOffcanvas = document.getElementById('navbarNav')
        myOffcanvas.addEventListener('show.bs.offcanvas', function () {
            $('.navbar-toggler').removeClass("collapsed");
        })
        myOffcanvas.addEventListener('hide.bs.offcanvas', function () {
            $('.navbar-toggler').addClass("collapsed");
        })
    }

    initMatrixContent() {
        if ($('.matrix-block').length) {
            import(/* webPackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }
}

export default App;